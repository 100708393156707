import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { animated, useSpring } from 'react-spring'

const InternationalItem = ({ title, projectRelated }) => {
  const [visible, setVisible] = useState(false)
  const [alignDir, setAlignDir] = useState(false)
  const itemRef = useRef()

  let halfOfWidth
  let halfOfHeight

  if (typeof document !== `undefined`) {
    halfOfWidth = window.innerWidth / 2
    halfOfHeight = window.innerHeight / 2
  }

  const itemLeft = itemRef.current ? itemRef.current.getBoundingClientRect().left : halfOfWidth
  const itemTop = itemRef.current ? itemRef.current.getBoundingClientRect().top : halfOfHeight
  const itemHeight = itemRef.current
    ? itemRef.current.getBoundingClientRect().top +
      itemRef.current.getBoundingClientRect().height * 6
    : halfOfHeight

  const trans = (x, y) => `translate3d(${x}px,${y}px,0)`

  const [props, set] = useSpring(() => ({
    xy: ['50%', '-50%'],
    config: { mass: 2, tension: 250, friction: 30 }
  }))

  const showProjectName = useSpring({
    opacity: visible ? 1 : 0,
    transform: visible ? 'translateX(0)' : 'translateY(10px)',
    config: { mass: 2, tension: 500, friction: 70 }
  })

  const colorProjectName = useSpring({
    color: visible ? '#F74EA1' : '#ffffff',
    config: { mass: 10, tension: 500, friction: 70 }
  })

  const scaleWrap = useSpring({
    transform: visible ? 'scale(1)' : 'scale(0.6)',
    zIndex: visible ? '100' : '0',
    config: { mass: 1, tension: 500, friction: 50 }
  })

  const imgScale = useSpring({
    transform: !visible ? 'scale(1.4)' : 'scale(1)',
    config: { mass: 1, tension: 500, friction: 50 }
  })

  const showImage = () => {
    itemRef.current.style.zIndex = '100'
    setVisible(true)
  }

  const hideImage = () => {
    setVisible(false)
    itemRef.current.style.zIndex = '0'
  }

  useEffect(() => {
    if (
      itemRef.current.getBoundingClientRect().top +
        itemRef.current.getBoundingClientRect().height / 2 <=
      window.innerHeight / 2
    ) {
      setAlignDir('up')
    } else {
      setAlignDir('dowm')
    }
  }, [])

  return (
    <Item
      ref={itemRef}
      onMouseMove={e => {
        set({
          xy: [
            e.clientX - itemLeft,
            alignDir === 'up' ? e.clientY - itemTop : e.clientY - itemHeight
          ]
        })
      }}
      onMouseEnter={showImage}
      onMouseLeave={hideImage}
      className={visible ? 'visible' : ''}
    >
      <WrapText>
        <animated.h2 style={colorProjectName} dangerouslySetInnerHTML={{ __html: title }} />

        {projectRelated.map((project, index) => (
          <div key={index}>
            <animated.h4
              style={showProjectName}
              dangerouslySetInnerHTML={{ __html: project.title }}
            />
            <HoverReveal
              style={{
                transform: props.xy.interpolate(trans)
              }}
            >
              <HoverRevealInner style={scaleWrap}>
                <HoverRevealImg
                  style={{
                    ...imgScale,
                    backgroundImage: `url(${project.workFields.featuredInfos.imagePortrait.sourceUrl})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                  }}
                ></HoverRevealImg>
              </HoverRevealInner>
            </HoverReveal>
          </div>
        ))}
      </WrapText>
    </Item>
  )
}

const HoverReveal = styled(animated.div)`
  position: absolute;
  z-index: -1;
  width: 280px;
  height: 360px;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  will-change: transform, opacity;
`

const Item = styled(animated.div)`
  position: relative;
  width: 100%;
  margin: 1.5rem 2rem;
  height: 60px;
  text-align: center;
  cursor: default;
  display: flex;
  justify-content: center;
  cursor: pointer;
  z-index: 0;

  &.visible {
    h2,
    h4 {
      z-index: 20;
    }

    ${HoverReveal} {
      z-index: 10;
      opacity: 1 !important;
    }
  }

  h2 {
    position: relative;
    width: auto;
    font-size: 58px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  h4 {
    position: relative;
    color: ${props => props.theme.colours.pink};
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    opacity: 0;
  }
`

const WrapText = styled.div`
  width: auto;
`

const HoverRevealInner = styled(animated.div)`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const HoverRevealImg = styled(animated.div)`
  position: relative;
  width: 100%;
  height: 100%;
`

export default InternationalItem
