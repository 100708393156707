import React, { useState, useEffect, useRef, useContext } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"
import SwiperEl from "react-id-swiper"
import { Swiper, Navigation } from "swiper/js/swiper.esm"

import "swiper/css/swiper.css"
import { animated, useSpring } from "react-spring"
import Observer from "../observer"
import ObserverMap from "../observerMap"
import { aspectRatio } from "../../helpers/aspect-ratio"

import { ScrollbarContext } from "../context/scrollbar-context"

import ArrowTeamNext from "../../images/arrow-team-next.svg"
import ArrowTeamPrev from "../../images/arrow-team-prev.svg"

export const fragment = graphql`
  fragment StudioBeliefsFragment on WordPress_Page_Studiofields {
    beliefs {
      title
      beliefList {
        image {
          sourceUrl
          mediaItemId
          modified
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        imageMobile {
          sourceUrl
          mediaItemId
          modified
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        title
        subtitle
      }
    }
  }
`

// const calc = o => `translateY(${o * 0.1}px) scale(1.3)`
const calc = o => `translateY(${o * 0.1 + 180}px) scale(1)`

const StudioBeliefs = ({ beliefs }) => {
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [contentToggle, setContentToggle] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [showText, setShowText] = useState(true)
  const [swiper, updateSwiper] = useState(null)
  const [scrollbar] = useContext(ScrollbarContext)
  const [heightWindow, setHeightWindow] = useState(0)
  const [isDesktop, setIsDesktop] = useState(true)
  const ref = useRef()
  const contentRef = useRef()

  const refBgImg = useRef()
  const [{ offset }, set] = useSpring(() => ({ offset: 0 }))

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  useEffect(() => {
    const fixedPosition = ({ offset = 0 }) => {
      if (refBgImg.current) {
        // wrapRef.current.style.top = `${offset.y}px`
        // refPara1.current.style.transform = `translateY(${offset.y / 5}px)`
        set({ offset: heightWindow * 2 + offset.y / -2 })
      }
    }

    // Hooks into the Smooth Scrollbar instance that's passed as context
    // to create an artificially fixed element, sync to the current
    // Smooth Scrollbar offset
    if (scrollbar) {
      scrollbar.addListener(status => fixedPosition(status))
    }

    return function cleanupListener() {
      if (scrollbar) {
        scrollbar.removeListener(status => fixedPosition(status))
      }
    }
  }, [heightWindow, scrollbar, set])

  const params = {
    Swiper,
    modules: [Navigation],
    parallax: true,
    // loop: true,
    // loopedSlides: 2,
    preloadImages: true,
    speed: 1250,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    renderPrevButton: () => (
      <PrevArrow className="swiper-button-prev">
        <ArrowTeamPrev />
      </PrevArrow>
    ),
    renderNextButton: () => (
      <NextArrow className="swiper-button-next">
        <ArrowTeamNext />
      </NextArrow>
    ),
  }

  let allSlides

  if (contentRef.current) {
    allSlides = [...contentRef.current.querySelectorAll(".swiper-slide")]
  }

  const titleAnimation = useSpring({
    transform: animatedTitle ? `translate(-50%,0px)` : `translate(-50%,50px)`,
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  useEffect(() => {
    if (swiper !== null) {
      swiper.on("slideChange", () => {
        setTimeout(() => {
          setCurrentIndex(swiper.realIndex)
        }, 250)
      })

      // console.log(swiper)

      swiper.on("slideChangeTransitionStart", () => {
        setShowText(false)
        setTimeout(() => {
          setShowText(true)
        }, 500)

        allSlides.map(slide => {
          console.log(slide)
          if (window !== undefined) {
            window.addEventListener("scroll", () => {
              slide.style.transform = offset.interpolate(calc)
            })
          }
        })
      })
    }
  }, [allSlides, offset, swiper])

  useEffect(() => {
    if (window !== undefined) {
      setHeightWindow(window.innerHeight)
    }
  }, [swiper])

  return (
    <Outer>
      <ObserverMap
        element={contentRef.current}
        toggle={contentToggle}
        setToggle={setContentToggle}
      >
        <Observer
          element={ref.current}
          toggle={animatedTitle}
          setToggle={setAnimatedTitle}
        >
          <WhyTitle style={titleAnimation} ref={ref}>
            {beliefs.title}
          </WhyTitle>

          <SwiperOuter ref={contentRef}>
            <SwiperEl getSwiper={updateSwiper} {...params}>
              {beliefs.beliefList.map((item, i) => (
                <WrapItem key={i}>
                  <WrapBgImg
                    ref={refBgImg}
                    data-swiper-parallax="85%"
                    className="swiper-parallax-bg"
                  >
                    <ImgBg
                      style={{
                        backgroundImage: `url(${
                          isDesktop
                            ? item.image.sourceUrl
                            : item.imageMobile.sourceUrl
                        })`,
                        transform: offset.interpolate(calc),
                      }}
                    />
                  </WrapBgImg>
                </WrapItem>
              ))}
            </SwiperEl>
          </SwiperOuter>
          <WrapText
            // style={contentAnimation}
            className={showText ? "active" : ""}
          >
            <BeliefTitle>{beliefs.beliefList[currentIndex].title}</BeliefTitle>
            <BeliefSubtitle>
              {beliefs.beliefList[currentIndex].subtitle}
            </BeliefSubtitle>
          </WrapText>
        </Observer>
      </ObserverMap>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  margin: 20rem 0 0 0;
  padding: 0;

  .swiper-container {
    /* width: calc(100% - 20rem); */
    width: 100%;
    /* height: auto; */
    height: 95vh;

    @media (max-width: 850px) {
      width: 100%;
      padding-bottom: 0;
    }

    img {
      opacity: 1 !important;
    }
  }

  .swiper-slide {
    width: 100%;
    /* opacity: 0.2;
    transition: opacity 0.6s ease; */
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    overflow: hidden;
    padding: 25px 0;

    @media (max-width: 850px) {
      width: 85%;
    }

    &.swiper-slide-active {
      opacity: 1;
    }
  }
`

const WrapBgImg = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  overflow: hidden;
`

const ImgBg = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  transform: translateY(0px) scale(1.2);

  @media (max-width: 650px) {
    transform: translateY(0px) scale(1) !important;
  }
`

const WhyTitle = styled(animated.h3)`
  position: absolute;
  top: 12.5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 5.5rem;
  text-align: center;

  @media (max-width: 1024px) {
    padding: 0 0 3rem 0;
    font-size: 7.15rem;
    top: 15%;
  }

  @media (max-width: 650px) {
    top: 15%;
    width: 100%;
    font-size: 7.15rem;
    padding: 3rem 0;
    max-width: 12ch;
  }

  @media (max-width: 450px) {
    top: 10%;
    width: 100%;
    font-size: 7.15rem;
    padding: 3rem 0;
    max-width: 12ch;
  }

  @media (max-width: 400px) {
    top: 20px;
    width: 100%;
    font-size: 5.5rem;
    padding: 1.5rem 0 3rem 0;
    max-width: 12ch;
  }
`

const SwiperOuter = styled.div`
  position: relative;
`

const WrapText = styled(animated.div)`
  position: absolute;
  top: 55%;
  left: 50%;
  width: 55%;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  transition: opacity 0.25s ease;
  opacity: 0;
  z-index: 2;

  &.active {
    opacity: 1;
  }

  @media (max-width: 1024px) {
    width: 80%;
    margin: 0 auto;
  }

  @media (max-width: 650px) {
    width: calc(100% - 6rem);
    margin: 0 auto;
  }
`

const BeliefTitle = styled(animated.h4)`
  position: relative;
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.sofia};
  font-weight: 600;
  font-size: 8rem;
  line-height: 0.9;
  max-width: 16ch;
  /* opacity: 0; */
  transition: opacity 0.8s 0.5s ease-in-out;

  &:before {
    content: "‟";
    position: absolute;
    top: -5rem;
    left: -15rem;
    font-size: 20rem;

    @media (max-width: 1024px) {
      top: -10rem;
      left: 50%;
      transform: translateX(-50%);
      font-size: 20rem;
    }

    @media (max-width: 650px) {
      top: -9rem;
      left: 50%;
      transform: translateX(-50%);
      font-size: 15rem;
    }

    @media (max-width: 400px) {
      top: -8.5rem;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12rem;
    }
  }

  @media (max-width: 1024px) {
    /* font-size: 10rem; */
    font-size: 8rem;
    text-align: center;
  }

  @media (max-width: 650px) {
    font-size: 6.3rem;
    line-height: 1;
    text-align: center;
  }

  @media (max-width: 400px) {
    font-size: 5.3rem;
    line-height: 1.2;
  }
`

const BeliefSubtitle = styled.p`
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 300;
  font-size: 2.2rem;
  line-height: 1.4;
  padding-top: 2.5rem;
  margin-left: 50%;
  /* opacity: 0; */
  transition: opacity 0.8s 0.5s ease-in-out;

  @media (max-width: 1024px) {
    /* margin-left: 33.33%;
    width: 66.66%; */
    font-size: 3rem;
    width: 85%;
    margin: 0 auto;
    text-align: center;
    font-size: 2.3rem;
  }

  @media (max-width: 650px) {
    width: 95%;
    padding-top: 5rem;
    margin: 0 auto;
    text-align: center;
    font-size: 2.2rem;
  }
`

const WrapItem = styled.div`
  position: relative;
  /* opacity: 0; */

  &.swiper-slide-active,
  &.swiper-slide-duplicate-active {
    /* ${WrapText} {
      transform: translate(-50%, -50%) !important;
      opacity: 1 !important;
    } */

    /* ${BeliefTitle} {
      opacity: 1;
    }
    ${BeliefSubtitle} {
      opacity: 1;
    } */
  }
`

const WhyItemImg = styled(Img)`
  ${aspectRatio(1440, 840)}
  transfrom: scale(1.5);

  @media (max-width: 1024px) {
    ${aspectRatio(820, 1024)}
  }

  @media (max-width: 650px) {
    ${aspectRatio(428, 1000)}
  }
`

const PrevArrow = styled.div`
  position: absolute;
  top: unset !important;
  bottom: 5% !important;
  right: calc(12.5% + 100px) !important;
  left: unset !important;
  height: 75px !important;
  width: 75px !important;
  background-color: transparent;
  border: 2px solid ${props => props.theme.colours.pink};
  border-radius: 50%;
  pointer-events: all;

  &:after {
    display: none;
  }

  @media (max-width: 1024px) {
    height: 65px !important;
    width: 65px !important;
    bottom: 20% !important;
    right: 52% !important;
  }

  @media (max-width: 650px) {
    height: 55px !important;
    width: 55px !important;
    bottom: 20% !important;
    right: 54% !important;

    svg {
      width: 50% !important;
      height: 50% !important;
    }
  }

  @media (max-width: 400px) {
    bottom: 10% !important;
  }
`

const NextArrow = styled.div`
  position: absolute;
  top: unset !important;
  bottom: 5% !important;
  right: 12.5% !important;
  height: 75px !important;
  width: 75px !important;
  background-color: transparent;
  border: 2px solid ${props => props.theme.colours.pink};
  border-radius: 50%;
  pointer-events: all;

  &:after {
    display: none;
  }

  @media (max-width: 1024px) {
    bottom: 20% !important;
    height: 65px !important;
    width: 65px !important;
    right: 40% !important;
  }

  @media (max-width: 650px) {
    bottom: 20% !important;
    height: 55px !important;
    width: 55px !important;
    right: 35% !important;

    svg {
      width: 50% !important;
      height: 50% !important;
    }
  }

  @media (max-width: 400px) {
    bottom: 10% !important;
  }
`

export default StudioBeliefs
