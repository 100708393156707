import React, { useState, useRef } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import usePortal from 'react-useportal'
import Observer from '../observer'

import CrossSvg from '../../images/cross.svg'
import PlaySvg from '../../images/play.svg'

export const fragmentQl = graphql`
  fragment StudioVideoFragment on WordPress_Page_Studiofields {
    videoInfos {
      videoFull {
        mediaItemUrl
      }
      videoLoop {
        mediaItemUrl
      }
    }
  }
`

const StudioVideo = ({ video }) => {
  const { ref, openPortal, closePortal, isOpen, Portal } = usePortal()
  const [animationToggle, setAnimationToggle] = useState(false)
  const wrapRef = useRef()

  const openPortalVideo = () => {
    openPortal()
    ref.current.play()
  }

  const closePortalVideo = () => {
    closePortal()
    ref.current.pause()
  }

  const videoAnimation = useSpring({
    transform: animationToggle ? `translate3d(0px,0px,0px)` : `translate3d(0,50px,0)`,
    opacity: animationToggle ? 1 : 0,
    config: { mass: 5, tension: 1000, friction: 200 }
  })

  return (
    <Outer>
      <Observer element={ref.current} toggle={animationToggle} setToggle={setAnimationToggle}>
        <VideoWrapper onClick={openPortalVideo} style={videoAnimation} ref={wrapRef}>
          <video autoPlay loop>
            <source src={video.videoLoop.mediaItemUrl} type="video/mp4" />
            <track kind="captions" src=""></track>
          </video>
          <WrapButton>
            <CircleWrap>
              <PlaySvg />
            </CircleWrap>
          </WrapButton>
        </VideoWrapper>
      </Observer>

      <Portal>
        <Modal className={isOpen ? 'active' : ''}>
          <ModalContents>
            <Close onClick={closePortalVideo}>
              <CrossSvg />
            </Close>
            <video ref={ref}>
              <source src={video.videoFull.mediaItemUrl} type="video/mp4" />
              <track kind="captions" src=""></track>
            </video>
          </ModalContents>
          <ModalBackground />
        </Modal>
      </Portal>
    </Outer>
  )
}

export default StudioVideo

const Outer = styled.section`
  position: relative;
  height: 100%;
`

const VideoWrapper = styled(animated.div)`
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 42.25%;
  overflow: hidden;

  @media (max-width: 1024px) {
    padding-bottom: 54.25%;
  }

  @media (max-width: 650px) {
    padding-bottom: 56.25%;
  }

  video {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
  }
`

const ModalContents = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0.9);
  transition: transform 0.3s ease;
  padding: 30px;
  z-index: 5;

  video {
    position: absolute;
    width: 80%;
    height: auto;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
  }
`

const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1;
`

const Modal = styled(animated.aside)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 100;
  &.active {
    opacity: 1;
    pointer-events: all;
    ${ModalContents} {
      transform: scale(1);
    }
  }
  p {
    color: ${props => props.theme.colours.darkGrey};
    margin-bottom: 0;
    strong {
      display: block;
      font-family: ${props => props.theme.fonts.circular};
      font-size: 5.8rem;
      letter-spacing: -0.1rem;
      color: ${props => props.theme.colours.pink};
      margin-top: 2rem;
    }
  }
`

const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 30px;
  outline: 0;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
  svg {
    width: 20px;
    height: 20px;
  }
`

const WrapButton = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 140px;
  width: 140px;
  z-index: 10;

  @media (max-width: 650px) {
    height: 100px;
    width: 100px;
  }
`

const CircleWrap = styled.span`
  height: 100%;
  width: 100%;
  outline: 0;
  cursor: pointer;
  z-index: 10;
  transition: fill 0.25s, transform 0.25s;

  &:after {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border: 5px solid ${props => props.theme.colours.white};
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    transition: fill 0.25s, transform 0.25s;
  }

  svg {
    position: absolute;
    left: 52.5%;
    top: 50%;
    width: 26px;
    height: 37px;
    transform: translate(-50%, -50%);
    z-index: 15;
    transition: fill 0.25s, transform 0.25s;

    @media (max-width: 650px) {
      width: 16px;
      height: 27px;
    }
  }

  &:hover {
    path {
      fill: ${props => props.theme.colours.pink};
    }
    &:after {
      border: 5px solid ${props => props.theme.colours.pink};
      transform: scale(1.2);
    }
  }
`
