import React, { useState, useEffect, useRef, useCallback } from "react"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"

const TeamItem = ({
  member,
  index,
  openPortal,
  currentPerson,
  setCurrentPerson,
}) => {
  const ref = useRef()
  const [isActive, setIsActive] = useState(false)

  const trans1 = (x, y) => `translate3d(${x / -12}px,${y / 12}px,0)`
  const trans2 = (x, y) => `translate3d(${x / 12}px,${y / -12}px,0)`

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }))

  // const titleAnim = useSpring({
  //   bottom: isActive ? '20px' : '-100px',
  //   config: { mass: 2, tension: 750, friction: 100 }
  // })

  // const handleMouseMove = useCallback(
  //   e => {
  //     const x =
  //       e.clientX -
  //       (ref.current.getBoundingClientRect().left + ref.current.getBoundingClientRect().width / 2)
  //     const y =
  //       e.clientY -
  //       (ref.current.getBoundingClientRect().top + ref.current.getBoundingClientRect().height / 2)
  //     set({ xy: [x, y] })
  //   },
  //   [set]
  // )

  useEffect(() => {
    if (currentPerson === index) {
      setIsActive(true)
    }

    // return window.removeEventListener('mousemove', handleMouseMove)
  }, [currentPerson, index])

  // const mouseLeavehandler = () => {
  //   setIsActive(false)
  //   set({ xy: [0, 0] })
  // }

  const mouseEnterhandler = e => {
    setCurrentPerson(index)
    // setIsActive(true)
  }

  return (
    <TeamItemWrap
      ref={ref}
      onClick={e => {
        openPortal(e)
        mouseEnterhandler()
      }}
    >
      <WrapTeamImage
        onMouseEnter={mouseEnterhandler}
        // onMouseLeave={mouseLeavehandler}
        // onMouseMove={handleMouseMove}
      >
        {/* <Image
          fluid={member.mainImage.imageFile.childImageSharp.fluid}
          style={{ position: 'absolute', transform: props.xy.interpolate(trans1) }}
        /> */}
        <Image
          fluid={member.blendImage.imageFile.childImageSharp.fluid}
          style={{ position: "absolute" }}
          className="fallback-img"
        />
        {/* <Image
          className="fallback-img"
          fluid={member.ieImage.imageFile.childImageSharp.fluid}
          style={{ position: 'absolute', transform: props.xy.interpolate(trans2) }}
        /> */}
        {/* <TextWrap
        // style={titleAnim}
        >
          <h3>{member.name}</h3>
          <p>{member.position}</p>
        </TextWrap> */}
        {/* <TextWrapMobile>
          <h3>{member.name}</h3>
          <p>{member.position}</p>
        </TextWrapMobile> */}
      </WrapTeamImage>
    </TeamItemWrap>
  )
}

const Image = styled(animated(Img))`
  top: -40px;
  left: -40px;
  width: calc(100% + 80px);
  height: calc(100% + 80px);
  /* mix-blend-mode: lighten; */
  /* mix-blend-mode: hard-light; */
  mix-blend-mode: normal;

  @media (max-width: 650px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  /* @supports (mix-blend-mode: lighten) {
    &.fallback-img {
      display: none;
      mix-blend-mode: unset;
    }
  } */
  @media (max-width: 650px) {
    &.fallback-img {
      display: block;
      /* mix-blend-mode: unset; */
      /* mix-blend-mode: overlay; */
      z-index: 10;
    }
  }
`

const WrapTeamImage = styled(animated.div)`
  position: relative;
  /* height: 100%; */
  height: 75%;
  width: 100%;
  overflow: hidden;

  @media (max-width: 650px) {
    height: 75%;
    /* height: 100%; */
  }
`

const TeamItemWrap = styled(animated.div)`
  margin-bottom: 2.5rem;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
`

const TextWrap = styled(animated.div)`
  position: absolute;
  left: 25px;
  display: block;

  @media (max-width: 1024) {
    bottom: 20px !important;
    display: none;
  }

  h3 {
    font-size: 30px;
    font-family: ${props => props.theme.fonts.sofia};
    font-weight: 700;
    text-align: left;
    margin-bottom: 0;

    @media (max-width: 1350px) {
      font-size: 25px;
    }
  }

  p {
    font-size: 18px;
    font-family: ${props => props.theme.fonts.circular};
    color: ${props => props.theme.colours.white};
    margin-bottom: 0;
    text-align: left;

    @media (max-width: 1350px) {
      font-size: 16px;
      line-height: 1.2;
    }
  }
`

export default TeamItem
