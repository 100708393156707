import React, { useState, useRef } from "react"
import styled from "styled-components"
import { useTrail } from "react-spring"
import ReactIdSwiperCustom from "react-id-swiper/lib/ReactIdSwiper.custom"
import { Swiper, Navigation } from "swiper/js/swiper.esm"
import Observer from "../observer"
import { aspectRatio } from "../../helpers/aspect-ratio"

import ArrowTeamNext from "../../images/arrow-team-next.svg"
import ArrowTeamPrev from "../../images/arrow-team-prev.svg"

import TeamItem from "./team-item"

import "swiper/css/swiper.css"

const TeamListDesk2 = ({
  teamMember,
  openPortal,
  currentPerson,
  setCurrentPerson,
}) => {
  const [animationToggle, setAnimationToggle] = useState(false)
  const [swiper, updateSwiper] = useState(null)
  const ref = useRef()

  const params = {
    Swiper,
    modules: [Navigation],
    slidesPerView: 1.4,
    spaceBetween: 20,
    slidesOffsetBefore: 25,
    slidesOffsetAfter: 25,
    grabCursor: true,
    freeMode: true,
    threshold: 20,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    renderPrevButton: () => (
      <PrevArrow className="swiper-button-prev">
        <ArrowTeamPrev />
      </PrevArrow>
    ),
    renderNextButton: () => (
      <NextArrow className="swiper-button-next">
        <ArrowTeamNext />
      </NextArrow>
    ),
    breakpoints: {
      760: {
        spaceBetween: 40,
        slidesPerView: 2.8,
        slidesOffsetBefore:
          typeof window !== `undefined` ? window.innerWidth * 0.1 : 0,
        slidesOffsetAfter:
          typeof window !== `undefined` ? window.innerWidth * 0.1 : 0,
      },
      1025: {
        spaceBetween: 50,
        slidesPerView: 4,
        slidesOffsetBefore:
          typeof window !== `undefined` ? window.innerWidth * 0.1 : 0,
        slidesOffsetAfter:
          typeof window !== `undefined` ? window.innerWidth * 0.1 : 0,
      },
      1600: {
        spaceBetween: 55,
        slidesPerView: 5,
        slidesOffsetBefore:
          typeof window !== `undefined` ? window.innerWidth * 0.1 : 0,
        slidesOffsetAfter:
          typeof window !== `undefined` ? window.innerWidth * 0.1 : 0,
      },
    },
  }

  const teamItemAnimation = useTrail(teamMember.length, {
    opacity: animationToggle ? 1 : 0,
    from: { opacity: 0 },
    config: {
      mass: 1,
      tension: 2500,
      friction: 175,
    },
    delay: animationToggle ? 300 : 50,
  })

  return (
    <>
      <Observer
        element={ref.current}
        toggle={animationToggle}
        setToggle={setAnimationToggle}
      >
        <TeamFeatured ref={ref}>
          <ReactIdSwiperCustom getSwiper={updateSwiper} {...params}>
            {teamItemAnimation.map(({ ...rest }, index) => {
              const teamItem = teamMember[index]
              return (
                <SlideGradient key={teamItem.title}>
                  <TeamItem
                    key={index}
                    index={index}
                    member={teamItem}
                    openPortal={openPortal}
                    currentPerson={currentPerson}
                    setCurrentPerson={setCurrentPerson}
                    // onMouseEnter={setCurrentPerson(currentPerson)}
                    style={{ ...rest }}
                  />
                  <WrapInfos>
                    <Name>{teamItem.name}</Name>
                    <Position>— {teamItem.position}</Position>
                  </WrapInfos>
                </SlideGradient>
              )
            })}
          </ReactIdSwiperCustom>
        </TeamFeatured>
      </Observer>
    </>
  )
}

const TeamFeatured = styled.div`
  /* height: 595px; */
  height: 45vw;

  @media (max-width: 1050px) {
    height: 60vw;
    /* height: 525px; */
    margin-bottom: 10rem;
  }

  @media (max-width: 650px) {
    height: 60vh;
    /* height: 525px; */
    margin-bottom: 10rem;
  }

  @media (min-width: 1600px) {
    /* height: 725px; */
    height: 35vw;
    margin-bottom: 10rem;
  }

  & > div {
    height: 75%;
  }

  .swiper-container {
    height: 100%;
    padding-bottom: 15rem;
    padding-top: 5rem;
  }
`

const SlideGradient = styled.div`
  position: relative;
  width: auto;
  ${aspectRatio(214, 225)}
  background: rgb(228,92,224);
  background: linear-gradient(
    215deg,
    rgba(228, 92, 224, 1) 10%,
    rgba(77, 169, 226, 1) 100%
  );
  border-radius: 2em;
  /* filter: drop-shadow(0 10px 25px rgba(0, 0, 0, 0.75)); */
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  flex-direction: column;

  @media (max-width: 650px) {
    ${aspectRatio(214, 365)}
  }
`

const WrapInfos = styled.div`
  position: absolute;
  /* height: 25%; */
  height: 15%;
  /* bottom: -2rem; */
  bottom: 0;
  top: unset;
  padding: 2rem;

  @media (max-width: 650px) {
    height: 25%;
    bottom: 0;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const Name = styled.p`
  font-size: 1.8rem;
  line-height: 1.5;
  color: ${props => props.theme.colours.white};
  margin: 0;

  @media (max-width: 650px) {
    font-size: 2.6rem;
  }
`

const Position = styled.p`
  font-size: 1.8rem;
  line-height: 1.25;
  color: ${props => props.theme.colours.white};
  margin: 0;

  @media (max-width: 650px) {
    font-size: 2.6rem;
  }
`

const PrevArrow = styled.div`
  position: absolute;
  top: unset !important;
  bottom: 0 !important;
  left: 10% !important;
  height: 75px !important;
  width: 75px !important;
  background-color: transparent;
  border: 2px solid ${props => props.theme.colours.pink};
  border-radius: 50%;
  pointer-events: all;

  &:after {
    display: none;
  }

  @media (max-width: 650px) {
    left: 25px !important;
  }
`

const NextArrow = styled.div`
  position: absolute;
  top: unset !important;
  bottom: 0 !important;
  left: calc(10% + 100px) !important;
  height: 75px !important;
  width: 75px !important;
  background-color: transparent;
  border: 2px solid ${props => props.theme.colours.pink};
  border-radius: 50%;
  pointer-events: all;

  &:after {
    display: none;
  }

  @media (max-width: 650px) {
    left: calc(25px + 100px) !important;
  }
`

export default TeamListDesk2
