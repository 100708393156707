import React from 'react'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import he from 'he'

export const fragment = graphql`
  fragment SeoFragment on WordPress_Page {
    seo {
      title
      metaDesc
      opengraphTitle
      opengraphDescription
      opengraphImage {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      twitterImage {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      twitterTitle
      twitterDescription
    }
  }
`

function SEO({
  pageTitle,
  title,
  metaDesc,
  opengraphTitle,
  opengraphDescription,
  opengraphImage,
  twitterTitle,
  twitterDescription,
  twitterImage
}) {
  const { wordPress } = useStaticQuery(
    graphql`
      query {
        wordPress {
          globalOptions {
            optionFields {
              seo {
                defaultSocialImage {
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    childImageSharp {
                      fixed(width: 1200, height: 630) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
              structuredData {
                organisation {
                  name
                  url
                }
              }
            }
          }
        }
      }
    `
  )

  const metaTitle = title || `${pageTitle} | KOTA`
  const metaDescription = metaDesc || ''
  const facebookTitle = opengraphTitle || title || `${pageTitle} | KOTA`
  const facebookDescription = opengraphDescription || metaDesc || ''
  const facebookType = 'website'
  const facebookImage = opengraphImage
    ? opengraphImage.imageFile.childImageSharp.fixed.src
    : wordPress.globalOptions.optionFields.seo.defaultSocialImage.imageFile.childImageSharp.fixed
        .src
  const twitterShareTitle = twitterTitle || title || `${pageTitle} | KOTA`
  const twitterShareDescription = twitterDescription || metaDesc || ''
  const twitterShareImage = twitterImage
    ? twitterImage.imageFile.childImageSharp.fixed.src
    : wordPress.globalOptions.optionFields.seo.defaultSocialImage.imageFile.childImageSharp.fixed
        .src

  return (
    <Helmet>
      <html lang="en" />
      <title>{he.decode(metaTitle)}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content={facebookType} />
      <meta property="og:title" content={he.decode(facebookTitle)} />
      <meta property="og:description" content={facebookDescription} />
      <meta property="og:site_name" content="KOTA" />
      <meta property="og:image" content={`https://kota.co.uk${facebookImage}`} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="kotacreative" />
      <meta name="twitter:creator" content="kotacreative" />
      <meta name="twitter:description" content={twitterShareDescription} />
      <meta name="twitter:title" content={he.decode(twitterShareTitle)} />
      <meta name="twitter:image" content={`https://kota.co.uk${twitterShareImage}`} />
      <meta name="format-detection" content="telephone=no" />
      <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "WebPage",
          "name": "${he.decode(metaTitle)}",
          "description": "${metaDescription}",
          "publisher": {
              "@type": "ProfilePage",
              "name": "${wordPress.globalOptions.optionFields.structuredData.organisation.name}"
          }
        }
      `}</script>
    </Helmet>
  )
}

export default SEO
