import React, { useState, useRef } from 'react'
import { useTrail, animated, useSpring } from 'react-spring'
import styled from 'styled-components'
import Observer from '../observer'

const TeamClient = ({ clients }) => {
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [animationToggle, setAnimationToggle] = useState(false)
  const titleRef = useRef()
  const contentRef = useRef()

  const titleAnimation = useSpring({
    transform: animatedTitle ? `translate(0px,0px)` : `translate(0,50px)`,
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const clientItemAnimation = useTrail(clients.edges.length, {
    opacity: animationToggle ? 1 : 0,
    x: animationToggle ? 0 : 20,
    from: { opacity: 0, x: 20 },
    config: {
      mass: 1,
      tension: 2500,
      friction: 175
    },
    delay: animationToggle ? 300 : 50
  })

  return (
    <Outer>
      <ClientBlock>
        <Observer element={titleRef.current} toggle={animationToggle} setToggle={setAnimatedTitle}>
          <Title style={titleAnimation} ref={titleRef}>
            International Clients
          </Title>
        </Observer>
        <Observer
          element={titleRef.current}
          toggle={animationToggle}
          setToggle={setAnimationToggle}
        >
          <ClientFeatured ref={contentRef}>
            {clientItemAnimation.map(({ x, ...rest }, index) => {
              const navItem = clients.edges[index]
              return (
                <ClientItem
                  style={{
                    ...rest,
                    transform: x.interpolate(x => `translate3d(0,${x}px,0)`)
                  }}
                  key={index}
                >
                  <img
                    src={navItem.node.clientFields.featuredInfos.logoWhite.imageFile.publicURL}
                    alt=""
                  />
                </ClientItem>
              )
            })}
          </ClientFeatured>
        </Observer>
      </ClientBlock>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 140rem;

  @media (max-width: 1024px) {
    max-width: 90rem;
  }
`

const ClientBlock = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;
  padding: 12.5rem 0 0 0;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 10rem 0;
  }

  @media (max-width: 650px) {
    width: 100%;
    padding: 1rem 0 5rem 0;
  }
`

const Title = styled(animated.h2)`
  /* color: ${props => props.theme.colours.white}; */
  color: var(--primary);
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 5.5rem;
  letter-spacing: -0.075rem;
  margin-bottom: 4rem;
  margin-left: 2.5rem;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 6.5rem;
    margin-left: 0;
    margin-bottom: 4rem;
  }

  @media (max-width: 650px) {
    font-size: 7.1rem;
    margin-left: 3.5rem;
    margin-bottom: 5rem;
  }
`

const ClientFeatured = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 175rem;
  margin: 0 auto;

  @media (max-width: 1024px) {
    justify-content: space-between;
  }

  @media (max-width: 650px) {
    justify-content: space-around;
    width: calc(100% - 7rem);
    padding: 0;
  }
`

const ClientItem = styled(animated.div)`
  width: 14.5%;
  margin: 2rem 2rem 4rem 2rem;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    width: 11%;
  }

  @media (max-width: 650px) {
    width: 20%;
  }

  img {
    max-height: 100%;
    max-width: 70%;
    margin-bottom: 0;

    @media (max-width: 650px) {
      max-width: 100%;
    }
  }
`

export default TeamClient
