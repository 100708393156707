import React, { useState, useContext, useRef } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import Observer from '../observer'
import { TransitionContext } from '../context/transition-context'

export const fragmentQl = graphql`
  fragment StudioIntroFragment on WordPress_Page_Studiofields {
    introInfos {
      title
      text
      textIntro
      buttonText
      buttonLink {
        ... on WordPress_Page {
          slug
        }
      }
    }
  }
`

const StudioIntro = ({ intro }) => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [animatedContent, setAnimatedContent] = useState(false)
  const [animatedButton, setAnimatedButton] = useState(false)
  const titleRef = useRef()
  const contentRef = useRef()
  const buttonRef = useRef()

  const titleAnimation = useSpring({
    transform: animatedTitle ? `translate(0px,0px)` : `translate(0,50px)`,
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const contentAnimation = useSpring({
    transform: animatedContent ? `translate(0px,0px)` : `translate(0,50px)`,
    opacity: animatedContent ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const buttonAnimation = useSpring({
    opacity: animatedButton ? 1 : 0,
    transform: animatedButton ? `translate(0px,0px)` : `translate(0,20px)`,
    config: { mass: 5, tension: 300, friction: 50 }
  })

  return (
    <Outer>
      <WrapIntro>
        <WrapText>
          <Observer element={titleRef.current} toggle={animatedTitle} setToggle={setAnimatedTitle}>
            <animated.h1 style={titleAnimation} ref={titleRef}>
              {intro.title}
            </animated.h1>
          </Observer>
          <Observer
            element={contentRef.current}
            toggle={animatedContent}
            setToggle={setAnimatedContent}
          >
            <animated.p
              style={contentAnimation}
              ref={contentRef}
              dangerouslySetInnerHTML={{ __html: intro.textIntro }}
            >
              {/* {intro.text} */}
              {/* {intro.textIntro} */}
            </animated.p>
          </Observer>
          <Observer
            element={buttonRef.current}
            toggle={animatedButton}
            setToggle={setAnimatedButton}
          >
            <IntroButton
              ref={buttonRef}
              style={buttonAnimation}
              to={`/${intro.buttonLink.slug}`}
              onClick={event => {
                event.preventDefault()
                setTransitionActive(true)
                setTimeout(() => {
                  navigate(`/${intro.buttonLink.slug}`)
                  setTransitionActive(false)
                }, 500)
              }}
            >
              {intro.buttonText}
            </IntroButton>
          </Observer>
        </WrapText>
      </WrapIntro>
    </Outer>
  )
}

export default StudioIntro

const Outer = styled.section`
  position: relative;
  height: auto;
`

const WrapIntro = styled.div`
  position: relative;
  height: 100%;
`

const WrapText = styled.div`
  height: auto;
  width: 55%;
  margin: 0 auto 0 15%;
  padding: 17.5rem 0;

  @media (min-width: 2000px) {
    height: auto;
    width: 40%;
    margin: 0 auto 0 15%;
    padding: 17.5rem 0;
  }

  @media (max-width: 1024px) {
    width: 70%;
    margin: 0 10%;
    position: relative;
    padding: 7.5rem 0;
  }

  @media (max-width: 650px) {
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 5rem 3rem 10rem 3rem;
  }

  h1 {
    /* color: ${props => props.theme.colours.grey}; */
    color: var(--primary);
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 4.5rem;
    line-height: 1.15;
    letter-spacing: -0.075rem;
    margin-bottom: 3rem;
    max-width: 15ch;

    @media (max-width: 1024px) {
      font-size: 5.5rem;
      width: 70%;
      margin-bottom: 4rem;
    }

    @media (max-width: 650px) {
      width: 100%;
      font-size: 5rem;
      /* max-width: 15ch; */
    }
  }

  p {
    /* color: ${props => props.theme.colours.grey}; */
    color: var(--primary);
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 1.75;

    @media (max-width: 1024px) {
      font-size: 2rem;
      line-height: 1.8;
    }

    @media (max-width: 650px) {
      font-size: 2.3rem;
      line-height: 1.8;
    }
  }
`

const IntroButton = styled(animated(Link))`
  background: transparent;
  border: 0.2rem solid ${props => props.theme.colours.pink};
  padding: 1.8rem 3.25rem;
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.circular};
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1rem;
  border-radius: 3rem;
  margin-top: 2.5rem;
  display: inline-block;
  transition: background-color 0.25s ease, color 0.25s ease;

  @media (max-width: 1024px) {
    margin-top: 3rem;
    margin-left: 0;
    font-size: 1.5rem;
    border-radius: 5rem;
    padding: 2.5rem 6rem;
  }

  @media (max-width: 650px) {
    font-size: 1.7rem;
    border-radius: 5rem;
    padding: 2.5rem 6rem;
  }

  &:hover {
    background-color: ${props => props.theme.colours.pink};
    color: ${props => props.theme.colours.white};
  }
`
