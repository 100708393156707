import React, { useState, useRef, useEffect, useContext } from "react"
import { graphql, Link, navigate } from "gatsby"
import { useTrail, useSpring, animated } from "react-spring"
import styled from "styled-components"
import ObserverCity from "../observerCity"
import ObserverMap from "../observerMap"
import { TransitionContext } from "../context/transition-context"
import { ScrollbarContext } from "../context/scrollbar-context"
import InternationalItem from "../InternationalItem"
// import BackgroundColor from '../background-color-grey'

export const fragment = graphql`
  fragment StudioInternationalFragment on WordPress_Page_Studiofields {
    international {
      background {
        sourceUrl
      }
      clientItem {
        cityName
        projectRelated {
          ... on WordPress_Project {
            id
            title
            slug
            workFields {
              featuredInfos {
                imagePortrait {
                  sourceUrl
                  altText
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 800, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const InternationalClient = ({ clients, background }) => {
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)
  const [scrollbar] = useContext(ScrollbarContext)
  const [animationToggle, setAnimationToggle] = useState(false)
  const [mapToggle, setMapToggle] = useState(false)
  const contentRef = useRef()
  const BgRef = useRef()

  useEffect(() => {
    const fixedPosition = ({ offset = 0 }) => {
      if (BgRef.current) {
        BgRef.current.style.top = `${offset.y}px`
      }
    }

    // Hooks into the Smooth Scrollbar instance that's passed as context
    // to create an artificially fixed element, sync to the current
    // Smooth Scrollbar offset
    if (scrollbar) {
      scrollbar.addListener(status => fixedPosition(status))
    }

    return function cleanupListener() {
      if (scrollbar) {
        scrollbar.removeListener(status => fixedPosition(status))
      }
    }
  }, [scrollbar])

  const clientItemAnimation = useTrail(clients.length, {
    opacity: animationToggle ? 1 : 0,
    x: animationToggle ? 0 : 50,
    from: { opacity: 0, x: 50 },
    config: {
      mass: 2,
      tension: 1000,
      friction: 100,
    },
    delay: 500,
  })

  const mapAnimation = useSpring({
    opacity: mapToggle ? 0.4 : 0,
    from: { opacity: 0 },
    config: {
      mass: 3,
      tension: 500,
      friction: 50,
    },
  })

  return (
    <Outer>
      {/* <BackgroundColor /> */}
      <ObserverMap
        element={contentRef.current}
        toggle={mapToggle}
        setToggle={setMapToggle}
      >
        <WrapBG ref={BgRef} style={mapAnimation}>
          <img src={background.sourceUrl} alt="" />
        </WrapBG>
      </ObserverMap>
      <InternationalBlock>
        <ObserverCity
          element={contentRef.current}
          toggle={animationToggle}
          setToggle={setAnimationToggle}
        >
          <InternationalFeatured ref={contentRef}>
            {clientItemAnimation.map(({ x, ...rest }, index) => {
              const navItem = clients[index]
              return (
                <IntLink
                  key={navItem.cityName}
                  to={`/work/${navItem.projectRelated[0].slug}`}
                  onClick={event => {
                    event.preventDefault()
                    setTransitionActive(true)
                    setTimeout(() => {
                      navigate(`/work/${navItem.projectRelated[0].slug}`)
                      setTransitionActive(false)
                    }, 500)
                  }}
                >
                  <InternationalItem
                    key={navItem.cityName}
                    title={navItem.cityName}
                    projectRelated={navItem.projectRelated}
                  />
                </IntLink>
              )
            })}
          </InternationalFeatured>
        </ObserverCity>
      </InternationalBlock>
    </Outer>
  )
}

const Outer = styled.div`
  margin: 0 auto;
  max-width: 100rem;
  /* padding: 50vh 0 0 0; */
  padding: 25vh 0 0 0;
`

const WrapBG = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  max-width: 1366px;
  top: 0;
  left: 50%;
  z-index: -1;
  will-change: top;
  transform: translate(-50%, 0%);

  img {
    width: 100%;
    height: 100%;
  }
`

const InternationalBlock = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;
  padding: 12.5rem 0;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 5rem 0;
  }

  @media (max-width: 650px) {
    width: 100%;
    padding: 1rem 0 5rem 0;
  }
`

const InternationalFeatured = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1024px) {
    justify-content: space-between;
  }

  @media (max-width: 650px) {
    justify-content: center;
    width: calc(100% - 7rem);
    padding: 0;
  }
`

const IntLink = styled(Link)`
  width: 100%;
  cursor: pointer;
`

export default InternationalClient
