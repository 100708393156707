import React, { useState, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom'
import { Swiper } from 'swiper/js/swiper.esm'

import 'swiper/css/swiper.css'
import { useTransition, animated, useSpring } from 'react-spring'
import Observer from '../observer'
import { aspectRatio } from '../../helpers/aspect-ratio'

export const fragment = graphql`
  fragment StudioWhyFragment on WordPress_Page_Studiofields {
    whyInfos {
      sectionTitle
      background {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      whyItem {
        itemDescription
        itemNumber
        itemTitle
        image {
          sourceUrl
          mediaItemId
          modified
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

const StudioWhy = ({ why }) => {
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [swiper, updateSwiper] = useState(null)
  const ref = useRef()

  const params = {
    Swiper,
    loop: true,
    loopedSlides: 3,
    preloadImages: true,
    centeredSlides: true,
    slidesPerView: 1.4,
    spaceBetween: 20,
    speed: 1000,
    threshold: 20,
    grabCursor: true,
    slideToClickedSlide: true,
    breakpoints: {
      560: {
        centeredSlides: true,
        spaceBetween: 40,
        slidesPerView: 2
      },
      760: {
        centeredSlides: true,
        spaceBetween: 60,
        slidesPerView: 2
      },
      1024: {
        centeredSlides: true,
        spaceBetween: 70,
        slidesPerView: 3
      }
    }
  }

  const titleAnimation = useSpring({
    transform: animatedTitle ? `translate(0px,0px)` : `translate(0,50px)`,
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const whyTitleTransition = useTransition(why.whyItem[currentIndex], item => item.itemTitle, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  const whyDescriptionTransition = useTransition(
    why.whyItem[currentIndex],
    item => item.itemDescription,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 }
    }
  )

  useEffect(() => {
    if (swiper !== null) {
      swiper.on('slideChange', () => setCurrentIndex(swiper.realIndex))
    }
  }, [swiper])

  return (
    <Outer>
      <WhyBg
        fluid={why.background.imageFile.childImageSharp.fluid}
        style={{ position: 'absolute' }}
      />

      <Observer element={ref.current} toggle={animatedTitle} setToggle={setAnimatedTitle}>
        <WhyTitle style={titleAnimation} ref={ref}>
          {' '}
          {why.sectionTitle}
        </WhyTitle>

        <SwiperOuter>
          {whyTitleTransition.map(({ item, key, props }) => (
            <Title key={key} style={{ ...props }}>
              {item.itemTitle}
            </Title>
          ))}

          <ReactIdSwiperCustom getSwiper={updateSwiper} {...params}>
            {why.whyItem.map((item, i) => (
              <WrapItem key={i}>
                <SmallNumber>{item.itemNumber}</SmallNumber>
                <WhyItemImg fluid={item.image.imageFile.childImageSharp.fluid} loading="eager" />
              </WrapItem>
            ))}
          </ReactIdSwiperCustom>
        </SwiperOuter>

        {whyDescriptionTransition.map(({ item, key, props }) => (
          <Description key={key} style={{ ...props }}>
            {item.itemDescription}
          </Description>
        ))}
      </Observer>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  padding: 0 0 20rem;

  .swiper-container {
    width: calc(100% - 20rem);
    height: auto;

    @media (max-width: 850px) {
      width: 100%;
      padding-bottom: 0;
    }

    img {
      opacity: 1 !important;
    }
  }

  .swiper-slide {
    width: 100%;
    opacity: 0.2;
    transition: opacity 0.6s ease;

    @media (max-width: 850px) {
      width: 85%;
    }

    &.swiper-slide-active {
      opacity: 1;
    }
  }
`

const WhyBg = styled(Img)`
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
`

const WhyTitle = styled(animated.h3)`
  position: relative;
  z-index: 2;
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 6rem;
  text-align: center;
  padding: 10rem 0 5rem 0;

  @media (max-width: 1024px) {
    padding: 8.5rem 0 3rem 0;
  }

  @media (max-width: 650px) {
    font-size: 5rem;
    padding: 8rem 0 3rem 0;
  }
`

const SwiperOuter = styled.div`
  position: relative;
  margin-bottom: 5rem;
`

const WrapItem = styled.div`
  position: relative;
`

const WhyItemImg = styled(Img)`
  ${aspectRatio(400, 400)}
`

const SmallNumber = styled.p`
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 600;
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
  margin: 0;

  @media (max-width: 1024px) {
    font-size: 1.75rem;
    line-height: 2;
  }

  @media (max-width: 650px) {
    margin-left: 0;
    font-size: 2.2rem;
  }
`

const Title = styled(animated.h3)`
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.sofia};
  font-size: 8rem;
  letter-spacing: 2rem;
  text-transform: uppercase;
  margin-bottom: 0;
  pointer-events: none;

  @media (max-width: 650px) {
    font-size: 7rem;
    letter-spacing: 1rem;
  }
`

const Description = styled(animated.p)`
  position: absolute;
  bottom: 10rem;
  left: 50%;
  transform: translateX(-50%);
  color: ${props => props.theme.colours.white};
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 300;
  font-size: 1.8rem;
  text-align: center;
  max-width: 60rem;
  z-index: 2;

  @media (max-width: 650px) {
    width: 100%;
    font-size: 2.25rem;
    padding: 0 3rem;
  }

  @media (max-width: 560px) {
    bottom: 6rem;
  }
`

export default StudioWhy
