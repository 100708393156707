import React, { useEffect } from "react"

const ObserverMap = ({ element, toggle, setToggle, children }) => {
  useEffect(() => {
    // Init a new observer
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0.5) {
            setToggle(true)
            // Stop observing as we only run the animation once
            // observer.unobserve(entry.target)
          } else {
            setToggle(false)
          }
        })
      },
      {
        threshold: [0, 0.25, 0.5, 0.75, 1],
      }
    )

    if (element) {
      observer.observe(element)
    }
  }, [element, setToggle, toggle])

  return <>{children}</>
}

export default ObserverMap
