import React, { useState, useRef } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import { useTrail, animated } from 'react-spring'
import Observer from '../observer'

export const fragmentQl = graphql`
  fragment StudioGalleryFragment on WordPress_Page_Studiofields {
    gallery {
      galleryItem {
        image {
          sourceUrl
          mediaItemId
          modified
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

const StudioGallery = ({ gallery }) => {
  const [animationToggle, setAnimationToggle] = useState(false)
  const ref = useRef()

  const galleryItemAnimation = useTrail(gallery.galleryItem.length, {
    opacity: animationToggle ? 1 : 0,
    x: animationToggle ? 0 : 20,
    from: { opacity: 0, x: 20 },
    config: {
      mass: 1,
      tension: 2500,
      friction: 175
    },
    delay: animationToggle ? 300 : 50
  })

  return (
    <Observer element={ref.current} toggle={animationToggle} setToggle={setAnimationToggle}>
      <Outer ref={ref}>
        <WrapIntro>
          {galleryItemAnimation.map(({ x, ...rest }, index) => {
            const galleryItem = gallery.galleryItem[index]
            return (
              <WrapImg
                key={index}
                style={{
                  ...rest,
                  transform: x.interpolate(x => `translate(0,${x}px)`)
                }}
              >
                <Img fluid={galleryItem.image.imageFile.childImageSharp.fluid} />
              </WrapImg>
            )
          })}
        </WrapIntro>
      </Outer>
    </Observer>
  )
}

export default StudioGallery

const Outer = styled.section`
  position: relative;
  height: auto;
`

const WrapIntro = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  width: 80%;
  margin: 0 auto 12.5rem auto;

  @media (max-width: 1024px) {
    margin: 0 auto 12.5rem auto;
    grid-template-rows: repeat(3, 130px);
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 270px);
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 1700px) {
      grid-template-rows: repeat(3, 300px);
    }

    @media (min-width: 2100px) {
      grid-template-rows: repeat(3, 350px);
    }

    @media (min-width: 2400px) {
      grid-template-rows: repeat(3, 400px);
    }

    @media (max-width: 1024px) {
      grid-template-rows: repeat(3, 220px);
    }

    @media (max-width: 650px) {
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 150px);
      grid-column-gap: 5px;
      grid-row-gap: 5px;
    }
  }
`

const WrapImg = styled(animated.div)`
  width: 100%;
  grid-column-start: auto;
  grid-row-start: auto;

  .gatsby-image-wrapper {
    height: 100%;
  }

  &:nth-child(1) {
    grid-area: 1 / 1 / 3 / 2;

    @media (max-width: 650px) {
      grid-area: 1 / 1 / 3 / 2;
    }
  }

  &:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;

    @media (max-width: 650px) {
      grid-area: 1 / 2 / 2 / 3;
    }
  }

  &:nth-child(3) {
    grid-area: 1 / 3 / 3 / 4;

    @media (max-width: 650px) {
      display: none;
    }
  }

  &:nth-child(4) {
    grid-area: 3 / 1 / 4 / 2;

    @media (max-width: 650px) {
      grid-area: 0 / 2 / 4 / 2;
    }
  }

  &:nth-child(5) {
    grid-area: 2 / 2 / 4 / 3;

    @media (max-width: 650px) {
    }
  }

  &:nth-child(6) {
    grid-area: 3 / 3 / 4 / 4;

    @media (max-width: 650px) {
      display: none;
    }
  }

  &:nth-child(7) {
    grid-area: 1 / 1 / 3 / 2;

    @media (max-width: 650px) {
      grid-area: 1 / 1 / 3 / 2;
    }
  }

  &:nth-child(8) {
    grid-area: 1 / 2 / 2 / 3;

    @media (max-width: 650px) {
      grid-area: 3 / 1 / 4 / 2;
    }
  }

  &:nth-child(9) {
    grid-area: 1 / 3 / 3 / 4;

    @media (max-width: 650px) {
      grid-area: 1 / 2 / 2 / 3;
    }
  }

  &:nth-child(10) {
    grid-area: 3 / 1 / 4 / 2;

    @media (max-width: 650px) {
      grid-area: 2 / 2 / 4 / 3;
    }
  }

  &:nth-child(11) {
    grid-area: 2 / 2 / 4 / 3;

    @media (max-width: 650px) {
      display: none;
    }
  }

  &:nth-child(12) {
    grid-area: 3 / 3 / 4 / 4;

    @media (max-width: 650px) {
      display: none;
    }
  }
`
