import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useRef,
} from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import usePortal from "react-useportal"
import Observer from "../observer"
import CrossSvg from "../../images/cross.svg"
import ChevronLeftSvg from "../../images/chevron-left.svg"
import ChevronRightSvg from "../../images/chevron-right.svg"

import { aspectRatio } from "../../helpers/aspect-ratio"

// import TeamListDesk from './team-list-desk'
import TeamListDesk from "./team-list-desk-2"
import TeamListMobile from "./team-list-mobile"

import TeamClient from "./client"

import BackgroundColor from "../background-color-grey"

export const fragment = graphql`
  fragment StudioTeamFragment on WordPress_Page_Studiofields {
    teamInfos {
      teamMember {
        position
        name
        bio
        mainImage {
          sourceUrl
          mediaItemId
          modified
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        blendImage {
          sourceUrl
          mediaItemId
          modified
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        ieImage {
          sourceUrl
          mediaItemId
          modified
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      image {
        sourceUrl
        mediaItemId
        modified
      }
      text
    }
  }

  fragment StudioClientFragment on WordPress_Client_Clientfields {
    featuredInfos {
      logoWhite {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          publicURL
        }
      }
    }
  }
`

const StudioTeam = ({ team, clients }) => {
  const ref = useRef()
  const svgRef = useRef()
  const contentRef = useRef()
  const trans1 = (x, y) => `translate3d(${x / -12}px,${y / 12}px,0)`
  const trans2 = (x, y) => `translate3d(${x / 12}px,${y / -12}px,0)`

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }))

  const [isDesktop, setIsDesktop] = useState(null)
  const [isHovered, setIsHovered] = useState(false)
  const [modalTransitioning, setModalTransitioning] = useState(false)
  const [currentPerson, setCurrentPerson] = useState(undefined)
  // eslint-disable-next-line no-unused-vars
  const [shuffleTeamMember, setShuffleTeamMember] = useState(() => {
    const teamMembers = team.teamMember
    teamMembers.sort(() => Math.random() - 0.5)
    return teamMembers
  })
  const { openPortal, closePortal, isOpen, Portal } = usePortal()

  const [animatedSvg, setAnimatedSvg] = useState(false)
  const [animatedContent, setAnimatedContent] = useState(false)

  const svgAnimation = useSpring({
    opacity: animatedSvg ? 1 : 0,
    transform: animatedSvg ? `translate(0px,0px)` : `translate(0,40px)`,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  const contentAnimation = useSpring({
    transform: animatedContent ? `translate(0px,0px)` : `translate(0,-500px)`,
    opacity: animatedContent ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  const modalContentTransition = useSpring({
    opacity: isOpen ? 1 : 0,
    from: {
      opacity: 0,
    },
    delay: 800,
    config: { duration: 500 },
  })

  const modalBlurTransition = useSpring({
    opacity: isOpen ? 1 : 0,
    from: {
      opacity: 0,
    },
    config: { duration: 700 },
  })

  function handleResize() {
    setIsDesktop(window.innerWidth > 1024)
  }

  const mouseLeavehandler = () => {
    set({ xy: [0, 0] })
  }

  const handleMouseMove = useCallback(
    e => {
      const x =
        e.clientX -
        (ref.current.getBoundingClientRect().left +
          ref.current.getBoundingClientRect().width / 2)
      const y =
        e.clientY -
        (ref.current.getBoundingClientRect().top +
          ref.current.getBoundingClientRect().height / 2)
      set({ xy: [x, y] })
      setIsHovered(true)
    },
    [set]
  )

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("mousemove", handleMouseMove)
    }
  }, [handleMouseMove, isDesktop, team.teamMember])

  const handleNext = () => {
    setModalTransitioning(true)
    setTimeout(() => {
      setCurrentPerson((currentPerson + 1) % shuffleTeamMember.length)
    }, 250)
    setTimeout(() => {
      setModalTransitioning(false)
    }, 500)
  }

  const handlePrev = () => {
    setModalTransitioning(true)
    setTimeout(() => {
      setCurrentPerson(
        (currentPerson + (shuffleTeamMember.length - 1)) %
          shuffleTeamMember.length
      )
    }, 250)
    setTimeout(() => {
      setModalTransitioning(false)
    }, 500)
  }

  return (
    <>
      <Outer>
        <TeamBlock>
          <BackgroundColor />
          <IntroWork>
            <Observer
              element={svgRef.current}
              toggle={animatedSvg}
              setToggle={setAnimatedSvg}
            >
              <WrapImg style={svgAnimation} ref={svgRef}>
                <img src={team.image.sourceUrl} alt={team.image.altText} />
              </WrapImg>
            </Observer>
            <Observer
              element={contentRef.current}
              toggle={animatedContent}
              setToggle={setAnimatedContent}
            ></Observer>

            <WrapText ref={contentRef}>
              <Content style={contentAnimation}> {team.text} </Content>
            </WrapText>
          </IntroWork>

          {/* {isDesktop && ( */}
          <TeamListDesk
            teamMember={shuffleTeamMember}
            openPortal={openPortal}
            currentPerson={currentPerson}
            setCurrentPerson={setCurrentPerson}
          />
          {/* )}
          {!isDesktop && (
            <TeamListMobile
              teamMember={shuffleTeamMember}
              openPortal={openPortal}
              currentPerson={currentPerson}
              setCurrentPerson={setCurrentPerson}
            />
          )} */}
        </TeamBlock>

        <Portal>
          <Modal className={isOpen ? "active" : ""}>
            {currentPerson !== undefined && (
              <ModalContents style={modalContentTransition}>
                {/* <ChevronLeft onClick={handlePrev}>
                  <ChevronLeftSvg />
                </ChevronLeft>
                <ChevronRight onClick={handleNext}>
                  <ChevronRightSvg />
                </ChevronRight> */}
                <Close onClick={closePortal}>
                  <CrossSvg />
                </Close>
                <ItemDesc>
                  <WrapTeamImage
                    ref={ref}
                    onMouseLeave={mouseLeavehandler}
                    onMouseMove={handleMouseMove}
                  >
                    <Image
                      fluid={
                        shuffleTeamMember[currentPerson].mainImage.imageFile
                          .childImageSharp.fluid
                      }
                      style={{
                        position: "absolute",
                        transform: isHovered
                          ? props.xy.interpolate(trans1)
                          : "translate3d(0, 0, 0)",
                      }}
                    />
                    <Image
                      fluid={
                        shuffleTeamMember[currentPerson].blendImage.imageFile
                          .childImageSharp.fluid
                      }
                      style={{
                        position: "absolute",
                        transform: isHovered
                          ? props.xy.interpolate(trans2)
                          : "translate3d(0, 0, 0)",
                      }}
                    />
                    <Image
                      className="fallback-img"
                      fluid={
                        shuffleTeamMember[currentPerson].ieImage.imageFile
                          .childImageSharp.fluid
                      }
                      style={{
                        position: "absolute",
                        transform: props.xy.interpolate(trans2),
                      }}
                    />
                  </WrapTeamImage>
                  <WrapDesc>
                    <h2>{shuffleTeamMember[currentPerson].name}</h2>
                    <p>{shuffleTeamMember[currentPerson].position}</p>
                    <p className="bio">
                      {" "}
                      {shuffleTeamMember[currentPerson].bio}{" "}
                    </p>
                  </WrapDesc>
                </ItemDesc>
                <BackFilter />
              </ModalContents>
            )}
            <ModalBackground
              // style={modalBlurTransition}
              className={isOpen ? "open" : ""}
            />
          </Modal>
        </Portal>

        <TeamClient clients={clients} />
      </Outer>
    </>
  )
}

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  /* max-width: 1366px; */
  width: 100%;
  /* background-color: ${props => props.theme.colours.footer}; */

  @media (min-width: 600px) {
    width: 100%;
  }

  @media (min-width: 1025px) {
    /* width: 922px; */
    width: 100%;
  }

  @media (min-width: 1200px) {
    /* width: 1080px; */
    width: 100%;
  }

  @media (min-width: 1519px) {
    width: 100%;
  }
`

const TeamBlock = styled.div`
  width: 100%;
  height: 100%;
`

const IntroWork = styled.div`
  display: block;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 15rem;
  margin: 0 auto 5rem auto;
  max-width: ${props => props.theme.sizes.maxWidth};

  @media (max-width: 1024px) {
    /* max-width: 90%; */
    max-width: 80%;
    margin: 0 auto;
  }

  @media (max-width: 650px) {
    max-width: 100%;
    margin: 0 auto;
    padding: 5rem 3rem 0;
  }
`

const WrapImg = styled(animated.div)`
  width: 31%;

  @media (max-width: 1024px) {
    margin-bottom: 0;
    width: 42%;
    margin-left: -0.5rem;
  }

  @media (max-width: 650px) {
    margin-bottom: 0;
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    width: 100%;
    margin-right: 0;
    float: right;
  }
`

const WrapText = styled.div`
  width: 52%;

  @media (max-width: 650px) {
    width: 45%;
  }

  @media (max-width: 650px) {
    width: 100%;
  }
`

const Image = styled(animated(Img))`
  top: -40px;
  left: -40px;
  width: calc(100% + 80px);
  height: calc(100% + 80px);
  mix-blend-mode: lighten;

  @media (max-width: 1024px) {
    height: 100%;
  }

  @supports (mix-blend-mode: lighten) {
    &.fallback-img {
      display: none;
    }
  }
`

const WrapTeamImage = styled.div`
  position: relative;
  height: 500px;
  width: 500px;
  overflow: hidden;
`

const ItemDesc = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 1024px) {
    margin-top: 10rem;
    font-size: 5rem;
    width: 66%;
    height: 100%;
    display: block;
    overflow: scroll;
  }

  @media (max-width: 650px) {
    margin-top: 0;
    font-size: 5rem;
    width: 100%;
    height: 100%;
    display: block;
    overflow: scroll;
  }

  > div {
    width: 50%;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  > ${WrapTeamImage} {
    max-width: 500px;
    margin-right: 120px;

    @media (max-width: 1024px) {
      ${Image} {
        ${aspectRatio(40, 40)}
      }
    }

    @media (max-width: 650px) {
      height: 50vh;
      margin-right: 0;

      ${Image} {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }
`

const WrapDesc = styled.div`
  @media (max-width: 650px) {
    padding: 20px 30px 15px 30px;
  }

  h2 {
    /* color: ${props => props.theme.colours.grey}; */
    color: ${props => props.theme.colours.white};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 4.5rem;
    letter-spacing: -0.075rem;

    @media (max-width: 1024px) {
      font-size: 5rem;
    }
  }

  p {
    color: ${props => props.theme.colours.pink};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 600;
    font-size: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;

    @media (max-width: 1024px) {
      margin-left: 0;
      font-size: 1.5rem;
    }

    @media (max-width: 650px) {
      margin-left: 0;
      font-size: 2.2rem;
    }
  }

  p.bio {
    /* color: ${props => props.theme.colours.grey}; */
    color: ${props => props.theme.colours.white};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 300;
    font-size: 1.6rem;
    text-transform: initial;
    letter-spacing: 0;

    @media (max-width: 1024px) {
      margin-left: 0;
      font-size: 2rem;
    }

    @media (max-width: 650px) {
      margin-left: 0;
      font-size: 2.2rem;
    }
  }
`

const Content = styled.p`
  color: var(--primary);
  /* color: ${props => props.theme.colours.white}; */
  font-family: ${props => props.theme.fonts.circular};
  font-weight: 300;
  font-size: 2.2rem;
  line-height: 1.4;
  margin-left: 16.5%;
  max-width: 32ch;

  @media (max-width: 1024px) {
    margin-left: 0;
    font-size: 2rem;
  }

  @media (max-width: 650px) {
    margin: 0 auto;
    font-size: 2.6rem;
    text-align: center;
    max-width: 90%;
  }
`

const ModalContents = styled(animated.div)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* transform: scale(0.9); */
  /* transition: all 0.7s ease; */
  padding: 30px;
  z-index: 50;

  @media (max-width: 650px) {
    padding: 0;
  }
`

const BackFilter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(36, 36, 36, 0.9);
  z-index: -1;
`

const ModalBackground = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px); */
  transition: backdrop-filter 0.3s linear;
  z-index: 1;
`

const Modal = styled(animated.aside)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
  z-index: 1000;

  &.active {
    opacity: 1;
    pointer-events: all;

    ${ModalContents} {
      transform: scale(1);
    }
  }
`

const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 30px;
  outline: 0;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;

  svg {
    width: 35px;
    height: 35px;
  }

  @media (max-width: 650px) {
    padding: 15px;
    top: 10px;
    right: 10px;

    rect {
      fill: ${props => props.theme.colours.white};
    }
  }
`

const ChevronLeft = styled.div`
  position: absolute;
  top: 50%;
  left: 3rem;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;

  @media (max-width: 650px) {
    top: 25vh;
  }

  svg {
    width: 4rem;
    height: 4rem;

    &:hover {
      path {
        stroke: ${props => props.theme.colours.pink};

        @media (max-width: 650px) {
          stroke: #fff;
        }
      }
    }

    path {
      stroke: ${props => props.theme.colours.grey};
      transition: stroke 0.3s ease;

      @media (max-width: 650px) {
        stroke: #fff;
      }
    }
  }
`

const ChevronRight = styled.div`
  position: absolute;
  top: 50%;
  right: 3rem;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;

  @media (max-width: 650px) {
    top: 25vh;
  }

  svg {
    width: 4rem;
    height: 4rem;

    &:hover {
      path {
        stroke: ${props => props.theme.colours.pink};

        @media (max-width: 650px) {
          stroke: #fff;
        }
      }
    }

    path {
      stroke: ${props => props.theme.colours.grey};
      transition: stroke 0.3s ease;

      @media (max-width: 650px) {
        stroke: #fff;
      }
    }
  }
`

export default StudioTeam
