import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
} from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import StudioHero from "../components/studio/hero"
import StudioVideo from "../components/studio/video"
import StudioIntro from "../components/studio/intro"
import StudioGallery from "../components/studio/gallery"
import StudioTeam from "../components/studio/team"
import StudioInternational from "../components/studio/international"
import StudioWhy from "../components/studio/why"
import StudioBeliefs from "../components/studio/beliefs"
import SEO from "../components/seo"
import InView from "../components/in-view"
import { ButtonContext } from "../components/context/button-context"

const Studio = ({ data, pageContext }) => {
  const [isDesktop, setIsDesktop] = useState(null)
  const [buttonTriggers, setButtonTriggers] = useContext(ButtonContext)
  const sectionRef = useRef()

  function handleResize() {
    setIsDesktop(window.innerWidth > 1024)
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [isDesktop])

  return (
    <Layout>
      <SEO
        pageTitle={pageContext.title}
        slug={pageContext.slug}
        {...data.wordPress.pageBy.seo}
      />
      <StudioHero hero={data.wordPress.pageBy.studioFields.heroInfos} />
      <StudioVideo video={data.wordPress.pageBy.studioFields.videoInfos} />
      <StudioIntro intro={data.wordPress.pageBy.studioFields.introInfos} />
      <StudioGallery gallery={data.wordPress.pageBy.studioFields.gallery} />
      <StudioTeam
        team={data.wordPress.pageBy.studioFields.teamInfos}
        clients={data.wordPress.clients}
      />
      {isDesktop && (
        <StudioInternational
          background={
            data.wordPress.pageBy.studioFields.international.background
          }
          clients={data.wordPress.pageBy.studioFields.international.clientItem}
        />
      )}
      {/* <StudioWhy why={data.wordPress.pageBy.studioFields.whyInfos} /> */}
      <InView
        element={sectionRef.current}
        setToggle={setButtonTriggers}
        location="cracking"
      >
        <div ref={sectionRef}>
          <StudioBeliefs beliefs={data.wordPress.pageBy.studioFields.beliefs} />
        </div>
      </InView>
    </Layout>
  )
}

export default Studio

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      pageBy(pageId: $wordpressId) {
        ...SeoFragment
        studioFields {
          ...StudioHeroFragment
          ...StudioVideoFragment
          ...StudioIntroFragment
          ...StudioGalleryFragment
          ...StudioTeamFragment
          ...StudioInternationalFragment
          ...StudioWhyFragment
          ...StudioBeliefsFragment
        }
      }
      clients(
        where: { orderby: { field: MENU_ORDER, order: ASC } }
        first: 50
      ) {
        edges {
          node {
            clientFields {
              ...StudioClientFragment
            }
          }
        }
      }
    }
  }
`
